var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-cnt" },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            height: "100%",
            border: true,
            "header-cell-style": { color: "#333333", background: "#EFF6FF" },
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              width: "100px",
              align: "center",
              label: "序号",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "logistic_business_name",
              align: "center",
              label: "集配中心",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$empty.empty(scope.row.logistic_business_name)
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "fullname", align: "center", label: "商品主图" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticClass: "goods-image",
                      attrs: { src: scope.row.kv, alt: "" },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "fullname", align: "center", label: "商品名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$empty.empty(scope.row.title)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "name", align: "center", label: "货号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$empty.empty(scope.row.sku_code)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "name", align: "center", label: "等级" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$empty.empty(scope.row.grade)) + "级 "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "name", align: "center", label: "毛重" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$empty.empty(scope.row.gross_weight)) +
                        "斤 "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "name", align: "center", label: "毛单价" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$empty.empty(scope.row.gross_weight_price)) +
                        "元/斤 "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "name", align: "center", label: "净重" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$empty.empty(scope.row.weight)) + "斤 "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "name", align: "center", label: "净单价" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$empty.empty(scope.row.weight_price)) +
                        "元/斤 "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "name", align: "center", label: "开市价" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.tool.toDecimal2(
                            (scope.row.price * 10000) / 100 / 10000
                          )
                        ) +
                        "元/斤 "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "name", align: "center", label: "供货商" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.supplier
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.$empty.empty(scope.row.supplier.title))
                          ),
                        ])
                      : _c("span", [_vm._v("—")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "name", align: "center", label: "业务员" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.buyer
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.$empty.empty(scope.row.buyer.fullname))
                          ),
                        ])
                      : _c("span", [_vm._v("—")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "name", align: "center", label: "创建时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$empty.empty(scope.row.create_time)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "name", align: "center", label: "库存数量" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$empty.empty(scope.row.stock)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "audit_status_txt",
              align: "center",
              label: "审核状态",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "state_txt", align: "center", label: "上架状态" },
          }),
          _c("el-table-column", {
            attrs: { prop: "sales", align: "center", label: "销量" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              fixed: "right",
              align: "center",
              "min-width": "130",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onHandelDetails(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看详情")]
                    ),
                    scope.row.audit_status == _vm.AUDIT_STATUS_ENUM["0"].value
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onHandleStatus(
                                  scope.row,
                                  _vm.AUDIT_STATUS_ENUM["2"].value
                                )
                              },
                            },
                          },
                          [_vm._v("审核")]
                        )
                      : _vm._e(),
                    scope.row.audit_status == _vm.AUDIT_STATUS_ENUM["0"].value
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onHandleStatus(
                                  scope.row,
                                  _vm.AUDIT_STATUS_ENUM["3"].value
                                )
                              },
                            },
                          },
                          [_vm._v("拒绝")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("GoodsDetails", { ref: "GoodsDetailsref" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }